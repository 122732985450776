export const app_key_arr = [
    "master",
    "keego",
    "gramobility",
    "moby",
    "citkar",
    "diageo",
    "abc_solar",
    "bombay_softwares",
    "biketek",
    "volti",
    "move",
    "ewaka",
]
