import React, { useState, useCallback, useMemo } from "react"
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table"
import { Button, Table } from "react-bootstrap"
import differenceBy from "lodash/differenceBy"
import DataTableExtensions from "react-data-table-component-extensions"
import DataTable from "react-data-table-component"
import "react-data-table-component-extensions/dist/index.css"

// Basic Table Data START******

export const BasicDataTable = ({ COLUMNS, DATATABLE }) => {
    const tableInstance = useTable(
        {
            columns: COLUMNS,
            data: DATATABLE,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    const {
        getTableProps, // table props from react-table
        headerGroups, // headerGroups, if your table has groupings
        getTableBodyProps, // table body props from react-table
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        state,
        setGlobalFilter,
        page, // use, page or rows
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
    } = tableInstance

    const { globalFilter, pageIndex, pageSize } = state

    return (
        <>
            <div className="e-table px-5 pb-5 table-responsive">
                <div className="d-block">
                    <select
                        className="mb-4 table-border me-1"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {[10, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>

                <Table {...getTableProps()} className="table-bordered text-nowrap border-bottom">
                    <thead>
                        {headerGroups.map((headerGroup, _index) => (
                            <tr key={`_HEADER${_index}`} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        key={`HEADER${index}`}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={column.className}
                                    >
                                        <span className="tabletitle">
                                            {column.render("Header")}
                                        </span>
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <i className="fa fa-angle-down"></i>
                                                ) : (
                                                    <i className="fa fa-angle-up"></i>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, _index) => {
                            prepareRow(row)
                            return (
                                <tr
                                    key={`ROW${_index}`}
                                    className="text-center"
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell, index) => (
                                        <td key={`ROW${index}`} {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="d-block d-sm-flex mt-4 ">
                    <span className="">
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span className="ms-sm-auto">
                        <Button
                            variant=""
                            className="btn-default tablebutton d-sm-inline d-block me-2 my-2"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {" Previous "}
                        </Button>
                        <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                                previousPage()
                            }}
                            disabled={!canPreviousPage}
                        >
                            {" << "}
                        </Button>
                        <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                                previousPage()
                            }}
                            disabled={!canPreviousPage}
                        >
                            {" < "}
                        </Button>
                        <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                                nextPage()
                            }}
                            disabled={!canNextPage}
                        >
                            {" > "}
                        </Button>
                        <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                                nextPage()
                            }}
                            disabled={!canNextPage}
                        >
                            {" >> "}
                        </Button>
                        <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {" Next "}
                        </Button>
                    </span>
                </div>
            </div>
        </>
    )
}
const GlobalFilter = ({ filter, setFilter }) => (
    <span className="d-flex ms-auto">
        <input
            value={filter || ""}
            onChange={(e) => setFilter(e.target.value)}
            className="form-control mb-4"
            placeholder="Search..."
        />
    </span>
)
