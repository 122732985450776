import React, { Fragment, Suspense, useEffect } from "react"
import BacktoTop from "../Layouts/Backtotop/Backtotop"
import Header from "../Layouts/Header/Header"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { openWebsite, setActiveWebsiteData } from "../redux/actions"
import { selectWebsites } from "../redux/auth/selectors"
import _ from "lodash"
import { useModalCtx } from "../providers/ModalProvider"
import { Helmet } from "react-helmet"

const CollapsedSidebar = React.lazy(() => import("./Sidebar/CollapsedSidebar"))
const Sidebar = React.lazy(() => import("../Layouts/Sidebar/Sidebar"))

const FullPreview = ({ fullView, children }) => {
    const { toggleFullScreen } = useModalCtx()

    useEffect(() => {
        window.addEventListener("resize", () => {
            document.getElementById("outer-container").style.height =
                `${window.innerHeight - document.getElementById("header").clientHeight}px`
        })

        document.getElementById("outer-container").style.height =
            `${window.innerHeight - document.getElementById("header").clientHeight}px`

        Array.from(document.getElementsByClassName("view_mode")).map((ele) =>
            ele.classList.add("view_mode_hide"),
        )

        const mainLayoutElement = document.getElementById("main-layout")

        if (
            ["/containers", "/routing", "/stations", "/vehicles"].includes(window.location.pathname)
        ) {
            mainLayoutElement.classList.remove("container-fluid")
            // toggleFullScreen(true)
        } else {
            mainLayoutElement.classList.add("container-fluid")
            // toggleFullScreen(false)
        }
    }, [window.location.pathname])

    return _.isUndefined(fullView) ? (
        <>
            <div className="sticky" style={{ paddingTop: "-74px" }}>
                <Sidebar />
            </div>
            <div className="jumps-prevent" style={{ paddingTop: "74px" }}></div>
            <div className="main-content app-content mt-0">
                <div className="side-app">
                    <div className="main-container container-fluid">{children}</div>
                </div>
            </div>
        </>
    ) : (
        <>
            <div
                className="sticky"
                style={{
                    paddingTop: "-74px",
                }}
            >
                <CollapsedSidebar />
            </div>
            <div
                className="jumps-prevent"
                style={{
                    paddingTop: "65px",
                }}
            ></div>
            <div
                className="main-content app-content-full-view mt-0"
                style={{ position: "relative", height: "100%", marginLeft: "5.35rem" }}
            >
                <div id="outer-container" className="outer-container">
                    {children}
                </div>
                <div
                    id="main-layout"
                    className="main-container container-fluid"
                    style={{ padding: "0px" }}
                >
                    {/* {children} */}
                </div>
            </div>
        </>
    )
}

const Layout = ({ children, fullView }) => {
    const Websites = useSelector(selectWebsites)
    const dispatch = useDispatch()

    useEffect(() => {
        const website = Websites.find((website) => website.id == sessionStorage.getItem("app"))
        dispatch(openWebsite(website))
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>
                    {localStorage.getItem("app_name")} | {process.env.REACT_APP_APP_NAME}
                </title>
            </Helmet>
            <Suspense fallback="">
                {/* <div className="page">
                    <div style={{ marginRight: "auto" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Header fullView={fullView} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">1asdkasjdaskd</div>
                        </div>
                    </div>
                </div> */}
                <div className="horizontalMenucontainer">
                    <div className="page">
                        <div className="page-main">
                            <Header fullView={fullView} />
                            <FullPreview fullView={fullView}>{children} </FullPreview>
                        </div>
                    </div>
                </div>
                {/* <div className="horizontalMenucontainer">
                    <div className="page">
                        <div className="page-main">
                            <Header fullView={fullView} />
                            <FullPreview fullView={fullView}>{children} </FullPreview>
                        </div>
                        <Footer />
                    </div>
                    <BacktoTop />
                </div> */}
            </Suspense>
        </Fragment>
    )
}

export default Layout
