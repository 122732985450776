import TextField from "./elements/TextField"

export const FormRender = ({ type, ...props }) => {
    switch (type) {
        case "textfield":
            return <TextField {...props} />
        default:
            return "Invalid Form Type"
    }
}
