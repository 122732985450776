import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
// import { Websites } from '../../Data/WebsiteData/efleety';
import Header from "../../Layouts/Header/Header"
import { fetchVendorVehicleIcons, openWebsite } from "../../redux/actions"
import { selectAuthUser, selectWebsites } from "../../redux/auth/selectors"
import { Helmet } from "react-helmet"
import { selectGeneralApiData, selectWebsiteData } from "../../redux/general/selectors"

import _ from "lodash"
import { Box, CircularProgress } from "@mui/material"
import { generalApiCall } from "../../redux/general/actions"
import { renderStyleSheet } from "../../css/styles"
import AdditionalInfo, { transformData } from "./AdditionalInfo"
import { GENERAL_API_CALLS } from "../../redux/redux_constants"
import { Assets } from "../../assets"

export const MapLogo = {
    master: [require("../../assets/images/media/master.png")],
    keego: [
        require("../../assets/images/media/motorcycle_green.png"),
        require("../../assets/images/media/scooter_green.png"),
    ],
    ewaka: [
        require("../../assets/images/media/motorcycle_green.png"),
        require("../../assets/images/media/scooter_green.png"),
    ],
    gramobility: [require("../../assets/images/media/scooter_green.png")],
    citkar: [require("../../assets/images/media/van_green.png")],
    diageo: [require("../../assets/images/media/van_green.png")],
    abc_solar: [require("../../assets/images/media/solar_panel_green.png")],
    superfy: [require("../../assets/images/media/bin_green.png")],
    moby: [require("../../assets/images/media/motorcycle_green.png")],
    bombay_softwares: [require("../../assets/images/media/motorcycle_green.png")],
    biketek: [require("../../assets/images/media/motorcycle_green.png")],
    volti: [require("../../assets/images/media/scooter_green.png")],
    move_scooters: [require("../../assets/images/media/scooter_green.png")],
}

const MAP_LOGOS = {
    master: require("../../assets/images/media/master.png"),
    solar_panel: require("../../assets/images/media/solar_panel_green.png"),
    bike: require("../../assets/images/media/motorcycle_green.png"),
    van: require("../../assets/images/media/van_green.png"),
    delivery_van: require("../../assets/images/media/van_green.png"),
    dustbin: Assets.GreenDustbin,
    "Delivery - KG3": require("../../assets/images/media/kg3_green.png"),
    Bike: require("../../assets/images/media/motorcycle_green.png"),
    "Delivery - KG4": require("../../assets/images/media/kg4_green.png"),
    Scooter: require("../../assets/images/media/scooter_green.png"),
    // container: require('../../assets/images/media/green-dustbin.png'),
    container: require("../../assets/images/media/green-tank.png"),
}

const IOT_TYPES_ICONS = [require("../../assets/images/media/motorcycle_green.png")]

function Website() {
    const [resultData, setResultData] = useState({})
    const dispatch = useDispatch()
    const user = useSelector(selectAuthUser)
    const Websites = useSelector(selectWebsites)
    const [arrayDashboard, setArrayDashboard] = useState([])

    const websiteTypes = useSelector(selectGeneralApiData({ key: "vendor_website_types" }))

    const efleetyStatistics = useSelector(selectGeneralApiData({ key: "wavsmart_statistics" }))
    const efleetyStatistics2 = useSelector(selectGeneralApiData({ key: "efleety_statistics" }))

    const keys = efleetyStatistics ? Object.keys(efleetyStatistics) : []
    const navigate = useNavigate()

    useEffect(() => {
        Array.from(document.getElementsByClassName("view_mode")).map((ele) =>
            ele.classList.add("view_mode_hide"),
        )
    }, [window.location.pathname])

    useEffect(() => {
        if (Websites.length == 1) {
            const website = Websites[0]
            sessionStorage.setItem("app", website.id)
            sessionStorage.setItem("app_key", website.app_key)
            localStorage.setItem("app_key", website.app_key)
            localStorage.setItem("app", website.id)
            dispatch(openWebsite(website))

            window.location.href = website.default_route
        } else if (Websites.length > 1) {
            const newArray = [...Websites]

            newArray.sort((a, b) => a.sequence - b.sequence)

            setArrayDashboard(newArray)
            dispatch(
                generalApiCall({
                    endpoint: "api/v1/vehicle/types",
                    params: {},
                    method: "GET",
                    key: GENERAL_API_CALLS.VENDOR_WEBSITE_TYPE,
                }),
            )
            dispatch(
                generalApiCall({
                    endpoint: "api/v1/get-all-stats",
                    params: {},
                    method: "GET",
                    key: GENERAL_API_CALLS.WAVSMART_STATISTICS,
                }),
            )
            // dispatch(openWebsite({}))
            if (Websites[1].app_key === "keego") {
                dispatch(
                    generalApiCall({
                        endpoint: "api/v1/get-all-summary",
                        params: {},
                        method: "GET",
                        key: GENERAL_API_CALLS.EFLEETY_STATISTICS,
                    }),
                )
            }
        }
    }, [])

    if (Websites.length < 2) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "100px",
                    justifyItems: "center",
                }}
            >
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            {renderStyleSheet({})}
            {console.log("keys", efleetyStatistics2)}

            <Helmet>
                <title>{process.env.REACT_APP_APP_NAME || ""}</title>
            </Helmet>
            <div className="horizontalMenucontainer">
                <div className="page-main">
                    <Header />
                    <div style={{ marginTop: "100px" }}>
                        {/* <Row>
<Col
style={{
padding: '30px',
fontSize: '20px',
fontWeight: 'bold',
textAlign: 'center',
}}
>
Websites
</Col>
</Row> */}
                        <Row>
                            <br />
                            {arrayDashboard.map((website, index) => (
                                <Col key={index} lg={3}>
                                    {console.log("website", website)}
                                    <Card
                                        onClick={() => {
                                            console.log("website: ", website)
                                            localStorage.setItem("app", website.id)
                                            localStorage.setItem("app_name", website.name)
                                            sessionStorage.setItem("app_key", website.app_key)
                                            sessionStorage.setItem("app", website.id)
                                            const coordinates = {
                                                lat: website.home_location_lat,
                                                lng: website.home_location_long,
                                            }

                                            const initialCoordinate = localStorage.getItem(
                                                `map_data_${localStorage.getItem("app")}`,
                                            )
                                                ? JSON.parse(
                                                      localStorage.getItem(
                                                          `map_data_${localStorage.getItem("app")}`,
                                                      ),
                                                  )
                                                : coordinates

                                            localStorage.setItem(
                                                `map_data_${website.id}`,
                                                JSON.stringify(initialCoordinate),
                                            )
                                            localStorage.setItem(
                                                `${website.name}map_data`,
                                                JSON.stringify(initialCoordinate),
                                            )

                                            window.location.href = website.default_route
                                        }}
                                        style={{ cursor: "pointer", width: "100%" }}
                                    >
                                        <Card.Body>
                                            <Card.Text style={{ textAlign: "center" }}>
                                                <img
                                                    src={website.logo}
                                                    height="100px"
                                                    width="auto"
                                                />

                                                {efleetyStatistics &&
                                                    Object.keys(
                                                        efleetyStatistics[website?.app_key] || {},
                                                    ).length > 0 && (
                                                        <AdditionalInfo
                                                            keys={keys}
                                                            app_key={website.app_key}
                                                            data={efleetyStatistics}
                                                            data2={efleetyStatistics2}
                                                        />
                                                    )}
                                                {console.log(
                                                    "Data is here",
                                                    efleetyStatistics,
                                                    efleetyStatistics,
                                                    2,
                                                )}
                                                <div
                                                    style={{
                                                        fontSize: "18px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: "12px",
                                                    }}
                                                >
                                                    {websiteTypes &&
                                                        websiteTypes[website.app_key]?.map(
                                                            (value, index2) => (
                                                                <Box key={index2} marginLeft="10px">
                                                                    <img
                                                                        src={MAP_LOGOS[value]}
                                                                        height="50px"
                                                                        width="auto"
                                                                    />
                                                                </Box>
                                                            ),
                                                        )}
                                                </div>
                                                {keys.includes("telus") && (
                                                    <div
                                                        className="d-flex justify-content-end mt-3"
                                                        style={{ gap: "8px" }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: "#3333ff",
                                                                color: "#fff",
                                                                minWidth: "4rem",
                                                            }}
                                                            className="info_chip"
                                                        >
                                                            {efleetyStatistics &&
                                                                efleetyStatistics[website?.app_key]
                                                                    ?.total_containers}
                                                        </div>
                                                        <div
                                                            style={{
                                                                backgroundColor: "#808080",
                                                                color: "#fff",
                                                                minWidth: "4rem",
                                                            }}
                                                            className="info_chip"
                                                        >
                                                            {efleetyStatistics &&
                                                                efleetyStatistics[website?.app_key]
                                                                    ?.disabled}
                                                        </div>
                                                    </div>
                                                )}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Website
