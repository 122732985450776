import React, { useEffect, useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { VehicleDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
// import { fetchData, setFilteredData } from '../../redux/assets/actions';
// import { setModule } from '../../redux/assets/initialState';
import AssetLocationMap from "../Maps/AssetLocationMap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import styles from "./DataTable.module.scss"
import { DashboardStatistics } from "../../Data/DummyData"
import { useInfoCtx } from "../../providers/InfoCardProvider"
import { useNavigate } from "react-router-dom"
// import { Modal } from 'react-bootstrap';
import { FormHelperText, Grid, MenuItem, Select, TextField } from "@mui/material"
import { useFormik } from "formik"
import { VehicleFormValidations } from "../../validations"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { addData, deleteData, editData } from "../../redux/actions"
import { addPage, fetchData, setFilteredData, setModule } from "../../redux/templateData/actions"
import {
    selectData,
    selectFilteredData,
    selectModuleSpecificData,
} from "../../redux/templateData/selectors"
import { FormRender } from "../../components/form"
import { useModalCtx } from "../../providers/ModalProvider"
import Modal from "../../components/Modal"
import { initialState } from "../../redux/templateData/init"

function GeneralVehicles() {
    const { renderInfoCardData } = useInfoCtx()
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)
    const modalContext = useModalCtx()
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext
    const vehicleData = useSelector(selectData)
    const batteryData = useSelector(selectModuleSpecificData("batteries"))
    const deviceData = useSelector(selectModuleSpecificData("iot"))
    const vehicleHistory = useSelector(selectModuleSpecificData("vehicle_history"))
    const [isEditMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const filteredResult = useSelector(selectFilteredData)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const [historyModalStatus, setHistoryModalStatus] = useState(false)

    const columns2 = React.useMemo(
        () =>
            computeColumns({
                data: vehicleHistory,
                meta: VehicleDatatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [vehicleHistory],
    )

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                vehicle_id: "",
                battery_id: "",
                number_of_rides: "",
                performance: "",
                current_location: "",
                current_status: "",
                distance_travelled: "",
                vehicle_active_time: "",
                vehicle_down_time: "",
                number_of_different_riders: "",
                distance_since_last_inspection: "",
                controller_firmware_version: "",
                controller_id: null,
                imei: null,
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: VehicleFormValidations,
        })

    const formMapping = [
        {
            type: "textfield",
            label: "Vehicle ID",
            name: "vehicle_id",
            placeholder: "Vehicle ID",
        },
    ]

    const columns = React.useMemo(
        () =>
            computeColumns({
                data: vehicleData,
                meta: VehicleDatatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [vehicleData],
    )

    const data = React.useMemo(() => vehicleData, [vehicleData])

    // useEffect(() => {
    //   let _data = vehicleData.map((item) => {
    //     return {
    //       ...item,
    //       status: simulation.find((data) => data.vehicle_id == item.vehicle_id)
    //         ?.status,
    //     };
    //   });

    //   setVehicleDataState([..._data]);
    // }, [simulation]);

    useEffect(() => {
        dispatch(setModule("vehicles"))
        dispatch(fetchData({ module: "vehicles" }))
    }, [])

    useEffect(() => {
        dispatch(setFilteredData(data))
    }, [vehicleData])

    return (
        <div className={styles.DataTable}>
            <PageHeader titles="Vehicles" active="Vehicles" items={["Tables"]} />
            <Row>
                {DashboardStatistics.filter((item) => item.module === "vehicle").map(
                    (card, index) => (
                        <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">{card.heading}</h6>
                                            <h2 className="mb-0 number-font">
                                                {renderInfoCardData(card)}
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ),
                )}
            </Row>
            <div>
                <AssetLocationMap
                    ids={VehicleDatatable.data.map((d) => d.vehicle_id)}
                    type={"vehicle_id"}
                    filteredResult={filteredResult}
                />
            </div>
            <Row>
                <Col lg={12} style={{ textAlign: "right" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <Button
                            onClick={() => {
                                setEditMode(false)
                                handleFormModalStatus(true)
                                resetForm()
                            }}
                            variant="secondary"
                        >
                            Add Vehicle
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                sortable_key="vehicle_id"
                                modalHeading="Details"
                                actions={[
                                    { name: "history", type: "history", buttonType: "icon" },
                                    { name: "Edit", type: "edit", buttonType: "icon" },
                                    { name: "Delete", type: "delete", buttonType: "icon" },
                                ]}
                                onActionClick={(event, { type, data }) => {
                                    setValues(data.original)
                                    if (type == "edit") {
                                        setEditMode(true)
                                        // console.log('data.original: ', data.original);
                                        handleFormModalStatus(true)
                                    } else if (type == "delete") {
                                        setDialogConfirmationStatus(true)
                                    } else if (type == "history") {
                                        dispatch(
                                            addPage({
                                                key: "vehicle_history",
                                                data: initialState.vehicles,
                                            }),
                                        )
                                        dispatch(
                                            fetchData({
                                                module: "vehicle_history",
                                                vehicle_id: data.values.vehicle_id,
                                            }),
                                        )
                                        setHistoryModalStatus(true)
                                    }
                                }}
                                isMultiSelect={false}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <Modal
                fullWidth
                maxWidth="md"
                disableSave={true}
                onClose={() => setHistoryModalStatus(false)}
                modalHeading="Vehicle Ride History"
                status={historyModalStatus}
                onSave={() => {
                    setHistoryModalStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Datatable
                            modalSize="xl"
                            isMultiSelect={false}
                            columns={columns2}
                            data={vehicleHistory}
                        />
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                modalHeading={isEditMode ? "Edit" : "Add"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    {/* {formMapping.map((inputField, index) => {
            return (
              <Grid key={index} xs={6} item>
                <FormRender
                  {...inputField}
                  error={
                    errors.vehicle_id && touched.vehicle_id ? true : false
                  }
                  helperText={
                    errors.vehicle_id && touched.vehicle_id
                      ? errors.vehicle_id
                      : false
                  }
                  value={values.vehicle_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            );
          })} */}
                    <Grid item xs={6}>
                        <TextField
                            label="Vehicle ID*"
                            name="vehicle_id"
                            disabled={!!isEditMode}
                            error={!!(errors.vehicle_id && touched.vehicle_id)}
                            helperText={
                                errors.vehicle_id && touched.vehicle_id ? errors.vehicle_id : false
                            }
                            value={values.vehicle_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Vehicle ID"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Device Id*"
                            select={true}
                            // disabled={isEditMode ? true : false}
                            name="device_id"
                            value={values.device_id}
                            error={!!(errors.device_id && touched.device_id)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Device Id"
                            fullWidth
                        >
                            <MenuItem selected disabled value="select">
                                Select Device Id
                            </MenuItem>
                            {deviceData?.map((device, index) => (
                                <MenuItem key={index} value={device.device_id}>
                                    {device.device_id}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Battery*"
                            placeholder="Select battery"
                            select={true}
                            name="battery_id"
                            value={values.battery_id}
                            error={!!(errors.battery_id && touched.battery_id)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        >
                            <MenuItem selected disabled value="select">
                                Select battery
                            </MenuItem>
                            {batteryData.map((battery, index) => (
                                <MenuItem key={index} value={battery.battery_id}>
                                    {battery.battery_id}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText error={!!(errors.battery_id && touched.battery_id)}>
                            {errors.battery_id && touched.battery_id ? errors.battery_id : ""}
                        </FormHelperText>
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="IMEI"
                            name="imei"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            error={!!(errors.imei && touched.imei)}
                            helperText={errors.imei && touched.imei ? errors.imei : false}
                            value={values.imei}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="IMEI Number"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Controller ID"
                            type="number"
                            name="controller_id"
                            InputProps={{ inputProps: { min: 0 } }}
                            error={!!(errors.controller_id && touched.controller_id)}
                            helperText={
                                errors.controller_id && touched.controller_id
                                    ? errors.controller_id
                                    : false
                            }
                            value={values.controller_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Controller ID"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Controller Firmware Version"
                            name="controller_firmware_version"
                            error={
                                !!(
                                    errors.controller_firmware_version &&
                                    touched.controller_firmware_version
                                )
                            }
                            helperText={
                                errors.controller_firmware_version &&
                                touched.controller_firmware_version
                                    ? errors.controller_firmware_version
                                    : false
                            }
                            value={values.controller_firmware_version}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Controller Firmware Version"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Number of Rides"
                            type="number"
                            name="number_of_rides"
                            InputProps={{ inputProps: { min: 0 } }}
                            error={!!(errors.number_of_rides && touched.number_of_rides)}
                            helperText={
                                errors.number_of_rides && touched.number_of_rides
                                    ? errors.number_of_rides
                                    : false
                            }
                            value={values.number_of_rides}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Number of Rides"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Performance"
                            name="performance"
                            error={!!(errors.performance && touched.performance)}
                            helperText={
                                errors.performance && touched.performance
                                    ? errors.performance
                                    : false
                            }
                            value={values.performance}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Performance"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Current Location"
                            name="current_location"
                            error={!!(errors.current_location && touched.current_location)}
                            helperText={
                                errors.current_location && touched.current_location
                                    ? errors.current_location
                                    : false
                            }
                            value={values.current_location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Current location"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Current Status"
                            name="current_status"
                            error={!!(errors.current_status && touched.current_status)}
                            helperText={
                                errors.current_status && touched.current_status
                                    ? errors.current_status
                                    : false
                            }
                            value={values.current_status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Current status"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Distance travelled"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            name="distance_travelled"
                            error={!!(errors.distance_travelled && touched.distance_travelled)}
                            helperText={
                                errors.distance_travelled && touched.distance_travelled
                                    ? errors.distance_travelled
                                    : false
                            }
                            value={values.distance_travelled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Distance travelled"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            label="Distance Since Last Inspection"
                            name="distance_since_last_inspection"
                            error={
                                !!(
                                    errors.distance_since_last_inspection &&
                                    touched.distance_since_last_inspection
                                )
                            }
                            helperText={
                                errors.distance_since_last_inspection &&
                                touched.distance_since_last_inspection
                                    ? errors.distance_since_last_inspection
                                    : false
                            }
                            value={values.distance_since_last_inspection}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="distance since travelled"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            label="Vehicle active time"
                            name="vehicle_active_time"
                            error={!!(errors.vehicle_active_time && touched.vehicle_active_time)}
                            helperText={
                                errors.vehicle_active_time && touched.vehicle_active_time
                                    ? errors.vehicle_active_time
                                    : false
                            }
                            value={values.vehicle_active_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Vehicle active time"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            label="Vehicle down time"
                            name="vehicle_down_time"
                            error={!!(errors.vehicle_down_time && touched.vehicle_down_time)}
                            helperText={
                                errors.vehicle_down_time && touched.vehicle_down_time
                                    ? errors.vehicle_down_time
                                    : false
                            }
                            value={values.vehicle_down_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Vehicle down time"
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            label="Number of different rides"
                            name="number_of_different_riders"
                            error={
                                !!(
                                    errors.number_of_different_riders &&
                                    touched.number_of_different_riders
                                )
                            }
                            helperText={
                                errors.number_of_different_riders &&
                                touched.number_of_different_riders
                                    ? errors.number_of_different_riders
                                    : false
                            }
                            value={values.number_of_different_riders}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Number of rides"
                        />
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        Are you sure want to delete this record?
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}

export default GeneralVehicles
