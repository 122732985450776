import React from "react"
import { TextField as MuiTextField } from "@mui/material"

function TextField(props) {
    return (
        <div>
            <MuiTextField fullWidth {...props} />
        </div>
    )
}

export default TextField
