// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/basic/mail_header.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heading {\n}\n\n.subheading {\n}\n\n.contact-header{\n    margin-top:20px;\n    background-size:100% 100%;\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height:400px;\n    object-fit:fit;\n    background-repeat:no-repeat;\n}\n    ", "",{"version":3,"sources":["webpack://./src/screens/Contact/contact.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;AACA;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,wDAAiE;IACjE,YAAY;IACZ,cAAc;IACd,2BAA2B;AAC/B","sourcesContent":[".heading {\n}\n\n.subheading {\n}\n\n.contact-header{\n    margin-top:20px;\n    background-size:100% 100%;\n    background-image:url('../../assets/images/basic/mail_header.png');\n    height:400px;\n    object-fit:fit;\n    background-repeat:no-repeat;\n}\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
