// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardFiltersOnMap_custom_chip_style__p1w8k {\n  border-radius: 50px;\n  margin-right: 20px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 4px;\n  cursor: default !important;\n  padding-top: 3px;\n  padding-bottom: 2px;\n  padding-left: 14px;\n  padding-right: 14px;\n  height: 26px;\n}", "",{"version":3,"sources":["webpack://./src/screens/Dashboard/DashboardFiltersOnMap.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,yCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,0BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AACJ","sourcesContent":[".custom_chip_style {\n    border-radius: 50px;\n    margin-right: 20px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    cursor: default !important;\n    padding-top: 3px;\n    padding-bottom: 2px;\n    padding-left: 14px;\n    padding-right: 14px;\n    height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_chip_style": "DashboardFiltersOnMap_custom_chip_style__p1w8k"
};
export default ___CSS_LOADER_EXPORT___;
