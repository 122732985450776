import { Button, Card, Col, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { VehicleDatatable, WorkOrderDatatable } from "../../Data/DummyData"
import AssetLocationMap from "../Maps/AssetLocationMap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useCallback, useEffect, useMemo, useState } from "react"
import RoutingMap from "../Maps/RoutingMap"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { selectData, selectFilteredData } from "../../redux/assets/selectors"
import { useDispatch, useSelector } from "react-redux"
import { setFilteredData } from "../../redux/assets/actions"
import { RouterMetaData } from "../../Data/WebsiteData/moby"

function GeneralRouting() {
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const filteredData = useSelector(selectFilteredData)
    const [filteredResult, setFilteredResult] = useState(filteredData)
    const dispatch = useDispatch()

    const data = useMemo(() => VehicleDatatable.data, [])

    useEffect(() => {
        dispatch(setFilteredData(data))
        setFilteredResult(data)
    }, [data])

    const columns = useMemo(() =>
        computeColumns({
            data: VehicleDatatable.data,
            meta: RouterMetaData,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const filterRoutingData = () => {
        setFilteredResult(filteredData)
    }

    const handleSearch = useCallback((event) => {
        // console.log(event.target.value);
    }, [])

    // useEffect(() => {
    //   setTimeout(() => filterRoutingData(), 3000);
    // }, [])

    return (
        <div>
            <PageHeader titles="Routing" active="Routing" items={["Tables"]} />
            <div>
                <RoutingMap filteredResult={filteredResult} />
            </div>
            <div className="row">
                <Col lg={12}>
                    <div style={{ marginBottom: "20px", textAlign: "right" }}>
                        <Button
                            size={"sm"}
                            variant="secondary"
                            onClick={() => filterRoutingData()}
                            // style={{ position: 'absolute', right: '3.3%', top: '7%' }}
                        >
                            Routing
                        </Button>
                    </div>
                    <Card>
                        <Card.Body>
                            <Datatable
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default GeneralRouting
