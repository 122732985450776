// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_Header__csJZ0 .Header_transparent-mode__p3gq1 .Header_app-header__l8aIu {\n  -webkit-backdrop-filter: blur(0.25rem) !important;\n          backdrop-filter: blur(0.25rem) !important;\n}\n\n.Header_app-sidebar__toggle__nQlH7 {\n  color: black !important;\n}", "",{"version":3,"sources":["webpack://./src/Layouts/Header/Header.module.scss"],"names":[],"mappings":"AACI;EACI,iDAAA;UAAA,yCAAA;AAAR;;AAIA;EACI,uBAAA;AADJ","sourcesContent":[".Header {\n    .transparent-mode .app-header {\n        backdrop-filter: blur(0.25rem) !important;\n    }\n}\n\n.app-sidebar__toggle {\n    color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "Header_Header__csJZ0",
	"transparent-mode": "Header_transparent-mode__p3gq1",
	"app-header": "Header_app-header__l8aIu",
	"app-sidebar__toggle": "Header_app-sidebar__toggle__nQlH7"
};
export default ___CSS_LOADER_EXPORT___;
