import React, { useEffect, useState } from "react"
import { Button, Card, Col, Offcanvas, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { VehicleIOTDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { selectIOTAssetData } from "../../redux/iot/selector"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { initialState } from "../../redux/templateData/init"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
} from "../../redux/templateData/selectors"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import styles from "./DataTable.module.css"
import { useModalCtx } from "../../providers/ModalProvider"
import Modal from "../../components/Modal"
import { FormHelperText, Grid, MenuItem, TextField } from "@mui/material"
import { useFormik } from "formik"
import { addData, deleteData, editData } from "../../redux/actions"
import { IOTValidation } from "../../validations"
import { unlockDevice } from "../../redux/iot/actions"
import { enqueueSnackbar } from "notistack"
import { DashboardStatistics, IOT_META } from "../../Data/WebsiteData/moby"
import { useInfoCtx } from "../../providers/InfoCardProvider"

let APIInterval

function BSIOT() {
    const module = "iot"
    const assetData = useSelector(selectIOTAssetData)
    const [isEditMode, setEditMode] = useState(false)
    const vehicleData = useSelector(selectModuleSpecificData("vehicles"))
    const iotTypes = useSelector(selectModuleSpecificData("iot_types"))
    const { renderInfoCardData } = useInfoCtx()

    const dispatch = useDispatch()
    const datatable = useSelector(selectDatatable)
    const filteredResult = useSelector(selectFilteredData)
    const [IOT_INSTRUCTIONS, setIOTInstructions] = useState([])
    const [openInstructionPanelStatus, setInstructionPanelStatus] = useState(false)
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)
    const [lockDialogConfirmationStatus, setLockDialogConfirmationStatus] = useState(false)

    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const modalContext = useModalCtx()
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                name: "",
                device_id: "",
                mac_address: null,
                imei: null,
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: IOTValidation,
        })

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const columns = React.useMemo(
        () =>
            computeColumns({
                data: datatable.data,
                meta: datatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable.data],
    )

    useEffect(() => {
        dispatch(fetchData({ module: "vehicles" }))

        APIInterval = setInterval(() => {
            dispatch(fetchData({ module: "iot" }))
        }, 5000)

        return () => {
            clearInterval(APIInterval)
        }
    }, [])

    const data = React.useMemo(() => datatable.data, [datatable.data])

    return (
        <div className="pt-3">
            <Offcanvas
                placement="end"
                name="end"
                show={openInstructionPanelStatus}
                onHide={() => setInstructionPanelStatus(false)}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Instructions</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {IOT_INSTRUCTIONS.map((instruction, index) => (
                        <div
                            key={`IOT${index}`}
                            style={{
                                marginBottom: "10px",
                                justifyContent: "space-between",
                            }}
                            className="d-flex align-items-center"
                        >
                            <div>{instruction}</div>
                            <div>
                                <button
                                    onClick={() => {
                                        if (instruction) {
                                            switch (instruction) {
                                                case "unlock":
                                                    dispatch(
                                                        unlockDevice({
                                                            imei: values.imei,
                                                            page_name: module,
                                                        }),
                                                    )
                                                    setTimeout(() => {
                                                        dispatch(fetchData({ module: "iot" }))
                                                    }, 3000)
                                                    setInstructionPanelStatus(false)
                                                    break
                                                default:
                                                    console.error("not a valid action")
                                            }
                                        }
                                    }}
                                    className="btn btn-secondary"
                                >
                                    Send
                                </button>
                            </div>
                        </div>
                    ))}
                </Offcanvas.Body>
            </Offcanvas>
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        {DashboardStatistics.filter((item) => item.module === "iot").map(
                            (card, index) => (
                                <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                    <Card className="overflow-hidden">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">{card.heading}</h6>
                                                    <h2 className="mb-0 number-font">
                                                        {renderInfoCardData(card)}
                                                    </h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1"></div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ),
                        )}
                    </Row>
                </Col>
            </Row>
            <PageHeader titles="Vehicles" active="Vehicles" items={["Tables"]} />
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                filteredResult={filteredResult}
                                addActionName="Add Device"
                                addAction={() => {
                                    setEditMode(false)
                                    handleFormModalStatus(true)
                                    resetForm()
                                }}
                                refreshAction={() => {
                                    dispatch(fetchData({ module }))
                                }}
                                datatableTitle="IOT"
                                sortable_key="id"
                                isMultiSelect={false}
                                rowSelected={(selection) => {
                                    // console.log(selection);
                                }}
                                actions={[
                                    {
                                        name: "Send",
                                        type: "lock",
                                        buttonType: "icon",
                                    },
                                    {
                                        name: "Send",
                                        type: "send_instruction",
                                        buttonType: "icon",
                                    },
                                    {
                                        name: "Edit",
                                        type: "edit",
                                        buttonType: "icon",
                                    },
                                    { name: "Delete", type: "delete", buttonType: "icon" },
                                ]}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                                onActionClick={(event, { type, data }) => {
                                    setValues(data.original)
                                    if (type == "send_instruction") {
                                        console.log(
                                            "data send Instructions: ",
                                            data.values.model_type,
                                        )
                                        const IOTDeviceInformation = iotTypes.find(
                                            (deviceModal) =>
                                                deviceModal.name == data.values.model_type,
                                        )

                                        setIOTInstructions(IOTDeviceInformation.allowed_features)
                                        setInstructionPanelStatus(true)
                                    } else if (type == "edit") {
                                        setEditMode(true)
                                        handleFormModalStatus(true)
                                    } else if (type == "delete") {
                                        setDialogConfirmationStatus(true)
                                    } else if (type == "lock") {
                                        setLockDialogConfirmationStatus(true)
                                        // setTimeout(() => {
                                        //   dispatch(fetchData({ module: 'iot' }));
                                        // }, 3000);
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <Modal
                modalHeading={isEditMode ? "Edit Device" : "Add Device"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Name*"
                            // disabled={isEditMode ? true : false}
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            error={!!(errors.name && touched.name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                        <FormHelperText error={!!(errors.name && touched.name)}>
                            {errors.name && touched.name ? errors.name : ""}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="QR Code"
                            // disabled={isEditMode ? true : false}
                            placeholder="QR Code"
                            name="qr_code"
                            value={values.qr_code}
                            error={!!(errors.qr_code && touched.qr_code)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                        <FormHelperText error={!!(errors.qr_code && touched.qr_code)}>
                            {errors.qr_code && touched.qr_code ? errors.qr_code : ""}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Model Type*"
                            placeholder="Model Type"
                            name="model_type"
                            value={values.model_type}
                            error={!!errors.model_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            select={true}
                        >
                            <MenuItem selected disabled value="select">
                                Select Device Id
                            </MenuItem>
                            {iotTypes?.map((device, index) => (
                                <MenuItem key={index} value={device.name}>
                                    {device.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText error={!!errors.model_type}>
                            {errors.model_type ? errors.model_type : ""}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Device Id*"
                            // disabled={isEditMode ? true : false}
                            placeholder="Select Vehicle"
                            name="device_id"
                            value={values.device_id}
                            error={!!(errors.device_id && touched.device_id)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                        <FormHelperText error={!!(errors.device_id && touched.device_id)}>
                            {errors.device_id && touched.device_id ? errors.device_id : ""}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Mac Address*"
                            name="mac_address"
                            error={!!(errors.mac_address && touched.mac_address)}
                            helperText={
                                errors.mac_address && touched.mac_address
                                    ? errors.mac_address
                                    : false
                            }
                            value={values.mac_address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Mac Address"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="IMEI Number*"
                            // type="number"
                            name="imei"
                            // disabled={isEditMode ? true : false}
                            error={!!(errors.imei && touched.imei)}
                            helperText={errors.imei && touched.imei ? errors.imei : false}
                            value={values.imei}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="IMEI Number"
                        />
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        Are you sure want to delete this record?
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setLockDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={lockDialogConfirmationStatus}
                onSave={() => {
                    dispatch(
                        unlockDevice({
                            imei: values.imei,
                            // module:""
                        }),
                    )
                    dispatch(fetchData({ module: "iot" }))
                    setLockDialogConfirmationStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        Are you sure want to unlock this IOT?
                    </Grid>
                </Grid>
            </Modal>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default BSIOT
