import React, { useMemo, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import NearMeIcon from "@mui/icons-material/NearMe"
import { selectDatatable } from "../../redux/templateData/selectors"
import { setMapViewStatus } from "../../redux/actions"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext.js"
import { fetchContainersByFilter } from "../../redux/templateData/actions.js"
import { selectMapViewStatus, selectWebsiteData } from "../../redux/general/selectors"

import "./RoutingDatatable.css"
import { setRoutingSummary } from "../../redux/routingSummary/reducers.js"
import { convertUTCToLocal } from "../../Data/TablesData/Datatable.js"
import { renderPageName } from "./FilteredContainerHistory.js"
import { app_key_arr } from "../../redux/globalConstants/globalConstants.js"
import htmlToPdf from "html-to-pdf-js"
import FileDownloadIcon from "@mui/icons-material/FileDownload"

const RoutingDataTable = React.forwardRef(
    ({ setTotalWasteFunc, onLocationButtonClick, actionButtonIndex }, ref) => {
        const display = useSelector((state) => state.routingSummary.displaySummary)
        const dispatch = useDispatch()
        const datatable = useSelector(selectDatatable)
        const data = React.useMemo(() => datatable.data, [datatable.data])
        const [clickedIconIndex, setClickedIconIndex] = useState(null)
        const websiteData = useSelector(selectWebsiteData)
        const [appKey, setAppKey] = useState(null)
        const result = useMemo(() => {
            return datatable.data.map((obj, index) => {
                const {
                    id,
                    substance,
                    routes,
                    total_containers,
                    created_at,
                    total_distance_km,
                    total_waste,
                    container_ids,
                    entity_ids,
                } = obj
                if (routes && routes[0]?.station) {
                    const { latitude, longitude } = routes[0].station
                    return {
                        DeviceID: id,
                        Substance: substance,
                        Latitude: latitude,
                        Longitude: longitude,
                        routes,
                        TotalContainers: total_containers,
                        CreatedAt: convertUTCToLocal(created_at).time,
                        TotalDistanceKM: total_distance_km,
                        TotalWaste: total_waste ? total_waste : 0,
                        ContainerIds: container_ids,
                        Entity_ID: entity_ids,
                    }
                } else {
                    return {
                        DeviceID: id,
                        Substance: substance,
                        routes,
                        TotalContainers: total_containers,
                        CreatedAt: convertUTCToLocal(created_at).time,
                        TotalDistanceKM: total_distance_km,
                        TotalWaste: total_waste ? total_waste : 0,
                        ContainerIds: container_ids,
                        Entity_ID: entity_ids,
                    }
                }
            })
        }, [datatable.data])
        const handleMouseEnter = () => {
            dispatch(setRoutingSummary("block"))
        }

        const handleMouseLeave = () => {
            dispatch(setRoutingSummary("none"))
        }

        const handleNearMeIconClick = (index) => {
            setClickedIconIndex(index)
        }

        // const app_key_arr = [
        //     "master",
        //     "keego",
        //     "gramobility",
        //     "moby",
        //     "citkar",
        //     "diageo",
        //     "abc_solar",
        //     "bombay_softwares",
        //     "biketek",
        //     "volti",
        //     "move",
        //     "ewaka",
        // ]

        // TODO: This logic is added just for wavsmart portal as of now need to modify later
        const generatePDF = (data) => {
            // Extracting assetsData arrays from routes and concatenating them into a single array
            if (data && data?.routes) {
                const allAssetsData = data?.routes?.map((route) => route.assetsData).flat()
                const htmlContent = `<html>
              <head>
                <style>
                  table {
                    border-collapse: collapse;
                    width: 95%;
                    margin: auto;
                  }
                  th, td {
                    border: 1px solid black;
                    padding: 10px;
                    text-align: left;
                  }
                  th {
                    background-color: #f2f2f2;
                  }
                  #e-pdf-title{
                    width: 95%;
                    margin:auto;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 1rem
                  }
                </style>
              </head>
              <body>
              <div id="e-pdf-title">
              <div>
                <p><b>Total Containers :</b> ${data.TotalContainers}</p>
                <p><b>Total Distance :</b> ${data.TotalDistanceKM} km</p>
                <p><b>Total Waste :</b> ${data.TotalWaste}</p>
              </div>
              <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <p> <b>Created At :</b> ${data.CreatedAt}</p>
              </div>
            </div>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Waste (liters)</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${allAssetsData
                        .map(
                            (row) => `
                      <tr key=${row.id}>
                        <td>${row.asset_id || "-"}</td>
                        <td>${row.fill_level || "-"}</td>
                        <td>${row.latitude || "-"}</td>
                        <td>${row.longitude || "-"}</td>
                      </tr>
                    `,
                        )
                        .join("")}
                  </tbody>
                </table>
              </body>
            </html>
          `
                const element = document.createElement("a")
                htmlToPdf()
                    .from(htmlContent)
                    .toPdf()
                    .get("pdf")
                    .then((pdf) => {
                        element.href = URL.createObjectURL(
                            new Blob([pdf.output("blob")], { type: "application/pdf" }),
                        )
                        element.download = "table.pdf"
                        element.click()
                    })
            } else {
                alert("No routes available")
            }
        }

        const App_Key = sessionStorage.getItem("app_key")

        useEffect(() => {
            setAppKey(sessionStorage.getItem("app_key"))
            console.log("sagew", appKey)
        }, [])
        return (
            <>
                {display === "block" && (
                    <div
                        onMouseEnter={() => handleMouseEnter()}
                        onMouseLeave={() => handleMouseLeave()}
                        style={{
                            top: "0px",
                            position: "absolute",
                            zIndex: "12",
                        }}
                    >
                        {console.log("safw", websiteData)}
                        {result.length > 0 && (
                            <TableContainer
                                component={Paper}
                                sx={{
                                    maxWidth: "410px",
                                    height: "93vh",
                                    overflow: "scroll",
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    {/* <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell align="left">Substance</TableCell>
                                <TableCell align="left">Routing</TableCell>
                            </TableRow>
                        </TableHead> */}
                                    <TableBody>
                                        {result.map((row, index) => (
                                            <>
                                                {" "}
                                                <TableRow key={index}>
                                                    <TableCell className="table-cell" align="left">
                                                        <span className="bold-label">ID:</span>{" "}
                                                        {row.DeviceID}
                                                    </TableCell>
                                                    {!app_key_arr.includes(
                                                        sessionStorage.getItem("app_key"),
                                                    ) && (
                                                        <TableCell
                                                            className="table-cell"
                                                            colSpan={1}
                                                            align="left"
                                                        >
                                                            <span
                                                                className="bold-label"
                                                                style={{ fontWeight: "bolder" }}
                                                            >
                                                                Substance:
                                                            </span>{" "}
                                                            <br />
                                                            {row.Substance &&
                                                            row.Substance.length > 20 ? (
                                                                <React.Fragment>
                                                                    {row.Substance.slice(0, 20)}{" "}
                                                                    <br />
                                                                    {row.Substance.slice(20)}
                                                                </React.Fragment>
                                                            ) : (
                                                                row.Substance
                                                            )}
                                                        </TableCell>
                                                    )}

                                                    <TableCell
                                                        className="table-cell"
                                                        align="left"
                                                        colSpan={2}
                                                    >
                                                        <span className="bold-label">
                                                            {app_key_arr.includes(
                                                                sessionStorage.getItem("app_key"),
                                                            )
                                                                ? "Bikes:"
                                                                : " Containers:"}
                                                        </span>{" "}
                                                        {row.TotalContainers}
                                                    </TableCell>

                                                    <TableCell
                                                        colSpan={2}
                                                        className="table-cell"
                                                        align="center"
                                                    >
                                                        <NearMeIcon
                                                            className={
                                                                actionButtonIndex === index
                                                                    ? "nearme-icon-clicked"
                                                                    : "nearme-icon"
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                onLocationButtonClick(index)
                                                                // handleNearMeIconClick(index)

                                                                setTotalWasteFunc(row?.TotalWaste)
                                                                document
                                                                    .getElementById("map")
                                                                    .scrollIntoView()

                                                                // setCoordinatesOriginDestination(data.values.routes)
                                                                ref.current.routesReset()
                                                                // setReport({
                                                                //     ...data.values,
                                                                //     total_routes: data.values.routes.length,
                                                                // })
                                                                ref.current.coordinates =
                                                                    row?.routes ? row.routes : null
                                                                ref.current.createRoute()
                                                                dispatch(
                                                                    fetchContainersByFilter({
                                                                        page_name: renderPageName(
                                                                            websiteData.app_key,
                                                                        ),
                                                                        entity_ids: row.Entity_ID,
                                                                    }),
                                                                )
                                                                dispatch(
                                                                    setMapViewStatus(
                                                                        toggleMapTable == "map_view"
                                                                            ? "table_view"
                                                                            : "map_view",
                                                                    ),
                                                                )
                                                                // dispatch(
                                                                //     fetchContainersByFilter({
                                                                //         page_name: renderPageName(),
                                                                //         entity_ids:
                                                                //             data.values.entity_ids,
                                                                //     }),
                                                                // )
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key={index + row.id}>
                                                    <TableCell></TableCell>
                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            paddingTop: "8px",
                                                        }}
                                                    >
                                                        <span className="bold-label">
                                                            Created At:
                                                        </span>
                                                        <br></br>
                                                        {row.CreatedAt}
                                                    </TableCell>
                                                    {app_key_arr.includes(
                                                        sessionStorage.getItem("app_key"),
                                                    ) ? null : (
                                                        <TableCell
                                                            colSpan={2}
                                                            align="left"
                                                            sx={{
                                                                paddingTop: "8px",
                                                            }}
                                                        >
                                                            <span className="bold-label">
                                                                Waste(ltrs):
                                                            </span>{" "}
                                                            {row.TotalWaste}
                                                        </TableCell>
                                                    )}

                                                    <TableCell
                                                        colSpan={2}
                                                        align="right"
                                                        sx={{
                                                            paddingTop: "8px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {!app_key_arr.includes(App_Key) && (
                                                            <FileDownloadIcon
                                                                onClick={() => {
                                                                    generatePDF(row)
                                                                }}
                                                                style={{
                                                                    fontSize: "1.7rem",
                                                                    color: "#00bc7f",
                                                                }}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    {/* <TableCell
                                                    colSpan={2}
                                                    align="left"
                                                    sx={{
                                                        paddingTop: "8px",
                                                    }}
                                                >
                                                    <span className="bold-label">
                                                        Total Distance:
                                                    </span>{" "}
                                                    {row.TotalDistanceKM
                                                        ? `${row.TotalDistanceKM} km`
                                                        : "0 km"}
                                                </TableCell> */}
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                )}
            </>
        )
    },
)

export default RoutingDataTable
