export const renderStyleSheet = (websiteData) => {
    const sidebarCSSFiles = {
        superfy: "sidebar-superfy.css",
        keego: "sidebar-keego.css",
        biketek: "sidebar-biketek.css",
        gramobility: "sidebar-gramobility.css",
        moby: "sidebar-moby.css",
        citkar: "sidebar-citkar.css",
        diageo: "sidebar-diageo.css",
        abc_solar: "sidebar-abc-solar.css",
        bombay_softwares: "sidebar-bs.css",
        move_scooters: "sidebar-move_scooters.css",
        volti: "sidebar-voltie.css",
        ewaka: "sidebar-ewaka.css",
        master: "sidebar-master.css",
        wav_smart: "sidebar-wav_smart.css",
        telus: "sidebar-telus.css",
        city_of_burnaby: "sidebar-city_of_burnaby.css",
        city_of_windsor: "sidebar-wav_smart.css",
        beyond_energy: "sidebar-wav_smart.css",
        tilt_sensor: "sidebar-wav_smart.css",
        wav_smart_parent: "sidebar-wav_smart.css",
        big_brother: "sidebar-wav_smart.css",
        get_wireless: "sidebar-wav_smart.css",
        groupe_bellemare: "sidebar-wav_smart.css",
        ecodine: "sidebar-wav_smart.css",
        gow: "sidebar-gow.css",
    }

    const defaultCSSFile = "sidebar-default.css"

    const cssFile = sidebarCSSFiles[websiteData.app_key] || defaultCSSFile

    import(`../Layouts/Sidebar/${cssFile}`)
}
