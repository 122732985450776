import { Card, Col, Row } from "react-bootstrap"
import { CusotmersData, CustomersData } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { BasicDataTable } from "../../Data/TablesData/TableData"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useMemo } from "react"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"

function GeneralCustomers() {
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: CustomersData.data,
            meta: CustomersData.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = useMemo(() => CustomersData.data, [])

    return (
        <div>
            <PageHeader titles="Customers" active="Customers" items={["Tables"]} />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Customers</h6>
                                            <h2 className="mb-0 number-font">
                                                {CustomersData.data.length}
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Active Customers</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    CustomersData.data.filter(
                                                        (data) => data.active == "Yes",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Inactive Customers</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    CustomersData.data.filter(
                                                        (data) => data.active == "No",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Inactive Customers</h6>
                      <h2 className="mb-0 number-font">5</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
                    </Row>
                </Col>
            </Row>
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Datatable</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Datatable
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default GeneralCustomers
