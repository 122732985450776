import React from "react"
import { Card, Col, ProgressBar, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import styles from "./Dashboard.module.scss"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { selectWebsiteData } from "../../redux/general/selectors"
import { DashboardStatistics } from "../../Data/WebsiteData/moby"
import { useInfoCtx } from "../../providers/InfoCardProvider"

const KeegoDashboard = () => {
    const websiteData = useSelector(selectWebsiteData)
    const { renderInfoCardData } = useInfoCtx()

    return (
        <div className={styles.Dashboard}>
            <PageHeader titles="Dashboard" active="Dashboard" items={["Home"]} />

            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        {DashboardStatistics.filter((item) => item.module === "dashboard").map(
                            (card, index) => (
                                <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                    <Card className="overflow-hidden">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">{card.heading}</h6>
                                                    <h2 className="mb-0 number-font">
                                                        {renderInfoCardData(card)}
                                                    </h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1"></div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ),
                        )}
                    </Row>
                </Col>
            </Row>
            {/* <!-- ROW-1 END --> */}

            {/* <!-- ROW-2 --> */}
            {/* <Row>
        <Col sm={12} md={12} lg={12} xl={8} xxl={9}>
          <Card>
            <Card.Header>
              <Card.Title as="h3">Orders</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="chartjs-wrapper-demo myChartSaah">
                <SalesAnalytics />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} sm={12} lg={12} xl={4} xxl={3}>
          <Card className="overflow-hidden">
            <Card.Body className="pb-0 bg-recentorder">
              <Card.Title as="h3" className="text-white">
                Recent Orders
              </Card.Title>
              <div className="chartjs-wrapper-demo">
                <RecentOrder />
              </div>
            </Card.Body>
            <div id="flotback-chart" className="flot-background"></div>
            <Card.Body>
              <div className="d-flex mb-4 mt-3">
                <div className="avatar avatar-md bg-secondary-transparent text-secondary bradius me-3">
                  <i className="fe fe-check"></i>
                </div>
                <div className="">
                  <h6 className="mb-1 fw-semibold">Delivered Orders</h6>
                  <p className="fw-normal fs-12">
                    {' '}
                    <span className="text-success mx-1">3.5%</span>
                    increased{' '}
                  </p>
                </div>
                <div className=" ms-auto my-auto">
                  <p className="fw-bold fs-20"> 70 </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="avatar  avatar-md bg-pink-transparent text-pink bradius me-3">
                  <i className="fe fe-x"></i>
                </div>
                <div className="">
                  <h6 className="mb-1 fw-semibold">Cancelled Orders</h6>
                  <p className="fw-normal fs-12">
                    {' '}
                    <span className="text-danger mx-1">1.2%</span>
                    decreased{' '}
                  </p>
                </div>
                <div className=" ms-auto my-auto">
                  <p className="fw-bold fs-20 mb-0"> 3,675 </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
            {/* <!-- ROW-2 END --> */}

            {/* <!-- ROW-3 --> */}
            {/* <Row>
        <Col xl={6} lg={6} md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3">Miles</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="chartjs-wrapper-demo myChartSaah">
                <SalesAnalytics />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6} lg={6} md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3" className="fw-semibold">
                Performance
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="browser-stats">
                <Row className="mb-4">
                  <Col sm={2} lg={3} xl={3} xxl={2} className="mb-sm-0 mb-3">
                    <Avatar size="35" round name="Ty Ayelloribbin" />
                  </Col>
                  <Col sm={10} lg={9} xl={9} xxl={10} className="ps-sm-0">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <h6 className="mb-1">Ty Ayelloribbin</h6>
                      <h6 className="fw-semibold mb-1">70% </h6>
                    </div>
                    <ProgressBar
                      variant="primary"
                      className="h-2 mb-3 "
                      now={70}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={2} lg={3} xl={3} xxl={2} className="mb-sm-0 mb-3">
                    <Avatar size="35" round name="Jack Aranda" />
                  </Col>
                  <Col sm={10} lg={9} xl={9} xxl={10} className="ps-sm-0">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <h6 className="mb-1">Jack Aranda</h6>
                      <h6 className="fw-semibold mb-1">40%</h6>
                    </div>
                    <ProgressBar
                      variant="secondary"
                      className="h-2 mb-3 "
                      now={40}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={2} lg={3} xl={3} xxl={2} className="mb-sm-0 mb-3">
                    <Avatar size="35" round name="Fran G. Pani" />
                  </Col>
                  <Col sm={10} lg={9} xl={9} xxl={10} className="ps-sm-0">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <h6 className="mb-1">Fran G. Pani</h6>
                      <h6 className="fw-semibold mb-1">50%</h6>
                    </div>
                    <ProgressBar
                      variant="success"
                      className="h-2 mb-3 "
                      now={50}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={2} lg={3} xl={3} xxl={2} className="mb-sm-0 mb-3">
                    <Avatar size="35" round name="Fran G. Pani" />
                  </Col>
                  <Col sm={10} lg={9} xl={9} xxl={10} className="ps-sm-0">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <h6 className="mb-1">Hugo First</h6>
                      <h6 className="fw-semibold mb-1">40%</h6>
                    </div>
                    <ProgressBar
                      variant="danger"
                      className="h-2 mb-3 "
                      now={40}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={2} lg={3} xl={3} xxl={2} className="mb-sm-0 mb-3">
                    <Avatar size="35" round name="Percy Vere" />
                  </Col>
                  <Col sm={10} lg={9} xl={9} xxl={10} className="ps-sm-0">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <h6 className="mb-1">Percy Vere</h6>
                      <h6 className="fw-semibold mb-1">15%</h6>
                    </div>
                    <ProgressBar
                      variant="warning"
                      className="h-2 mb-3 "
                      now={15}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={2} lg={3} xl={3} xxl={2} className="mb-sm-0 mb-3">
                    <Avatar size="35" round name="Olive Tree" />
                  </Col>
                  <Col sm={10} lg={9} xl={9} xxl={10} className="ps-sm-0">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <h6 className="mb-1">Olive Tree</h6>
                      <h6 className="fw-semibold mb-1">5%</h6>
                    </div>
                    <ProgressBar
                      variant="info"
                      className="h-2 mb-3 "
                      now={10}
                    />
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

            {/* <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3" className="mb-0">
                Events
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-4">
              <div className="">
                <div className="panel panel-primary">
                  <div className="tabs-menu-body pt-0">
                    <div className="tab-content p-0">
                      <div className="tab-pane active">
                        <div className="table-responsive">
                          <Tab.Container
                            id="left-tabs-example table-bordered"
                            defaultActiveKey="AllProducts"
                          >
                            <Nav variant="pills" className="product-sale">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="AllProducts"
                                  className="text-dark"
                                >
                                  All Events
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Shipped"
                                  className="text-dark"
                                >
                                  Shipped
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Pending"
                                  className="text-dark"
                                >
                                  Pending
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Cancelled"
                                  className="text-dark"
                                >
                                  Cancelled
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="AllProducts">
                                <AllProduct />
                              </Tab.Pane>
                              <Tab.Pane eventKey="Shipped">
                                <Shipped />
                              </Tab.Pane>
                              <Tab.Pane eventKey="Pending">
                                <Pending />
                              </Tab.Pane>
                              <Tab.Pane eventKey="Cancelled">
                                <Cancelled />
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
        </div>
    )
}

export default KeegoDashboard
