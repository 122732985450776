import React, { useEffect, useState } from "react"
import { Card, Col, Offcanvas } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { IOT_INSTRUCTIONS } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { selectFilteredData } from "../../redux/assets/selectors"
import { fetchIOTData } from "../../redux/iot/actions"
import { selectIOTAssetData } from "../../redux/iot/selector"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import styles from "./DataTable.module.css"

function GeneralIOT() {
    const assetData = useSelector(selectIOTAssetData)
    const dispatch = useDispatch()
    const filteredResult = useSelector(selectFilteredData)
    const [openInstructionPanelStatus, setInstructionPanelStatus] = useState(false)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = React.useMemo(
        () =>
            computeColumns({
                data: assetData.data,
                meta: assetData.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [assetData],
    )

    const data = React.useMemo(() => assetData.data, [assetData])

    useEffect(() => {
        dispatch(fetchIOTData())
    }, [])

    return (
        <div className={styles.DataTable}>
            <Offcanvas
                placement="end"
                name="end"
                show={openInstructionPanelStatus}
                onHide={() => setInstructionPanelStatus(false)}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Instructions</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {IOT_INSTRUCTIONS.map((instruction, index) => (
                        <div
                            key={`IOT${index}`}
                            style={{
                                marginBottom: "10px",
                                justifyContent: "space-between",
                            }}
                            className="d-flex align-items-center"
                        >
                            <div>{instruction.name}</div>
                            <div>
                                <button className="btn btn-secondary">Send</button>
                            </div>
                        </div>
                    ))}
                </Offcanvas.Body>
            </Offcanvas>
            <PageHeader titles="Vehicles" active="Vehicles" items={["Tables"]} />
            {/* <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Vehicles</h6>
                      <h2 className="mb-0 number-font">
                        {VehicleDatatable.data.filter(data=>data.type=='bike').length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">In Transit</h6>
                      <h2 className="mb-0 number-font">20</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Issues</h6>
                      <h2 className="mb-0 number-font">
                        {IssuesDatatable.data.length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Work Orders</h6>
                      <h2 className="mb-0 number-font">
                        {WorkOrderDatatable.data.length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}

            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                sortable_key="id"
                                isMultiSelect={false}
                                rowSelected={(selection) => {
                                    // console.log(selection)
                                }}
                                actions={[{ name: "Send Instruction", type: "send_instruction" }]}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                                onActionClick={(event, { type }) => {
                                    if (type == "send_instruction") {
                                        setInstructionPanelStatus(true)
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default GeneralIOT
