import axios from "axios"
import { enqueueSnackbar } from "notistack"

// let BASE_URL = 'https://efleety.com/';
let BASE_URL = process.env.REACT_APP_API_URL

const instance = axios.create()

instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
)

instance.interceptors.response.use(
    (response) =>
        // console.log('RESPONSE INTERCEPTER');
        response,
    (error) => Promise.reject(error),
)

const http = (method, endpoint, body = {}) => {
    const { headers, apiData, formData } = body

    if (formData) {
        console.log("formDataL")
    } else console.log("apiData")

    if (body.baseURL) {
        BASE_URL = body.baseURL
    }

    const url = `${BASE_URL}${endpoint}`

    // console.log('URL: ', url, process.env);

    const configurations = {
        url,
        method,
        headers: {
            "Client-Type": "web",
            Accept: "application/json",
            "Content-Type": "application/json",
            "App-Key": sessionStorage.getItem("app_key") || "keego",
            Authorization: localStorage.getItem("token"),
            ...headers,
        },
        data: formData ? formData : apiData,
        timeout: 20000,
        withCredentials: false,
        responseType: "json",
        validateStatus(status) {
            return status >= 200 && status < 300
        },
    }

    if (method == "GET") {
        configurations.params = apiData
    }

    return instance(configurations)
        .then((result) => {
            const { status } = result

            console.log("STATUS:", status)
            const data = result.data.data
            if (data) {
                if (typeof data === "object") {
                    result.data.data = data
                } else result.data.data = JSON.parse(data)
                console.group(`API RESPONSE GROUP: ${endpoint}; module: ${apiData?.page_name}`)
                console.log("data: ", result)
                console.groupEnd("API RESPONSE GROUP")

                return result.data
            } else return true
        })
        .catch((er) => {
            console.log("er: ", er)
            if (er.response.status == 400) {
                // console.log(
                //   'er.response.data.error.response: ',
                //   er.response.data.message
                // );
                const error = new Error(er.response.data.message)

                enqueueSnackbar(er.response.data.message, {
                    variant: "error",
                    anchorOrigin: {
                        horizontal: "right",
                        vertical: "bottom",
                    },
                })
            } else return error
        })
}

export default http
