import { Button, Card, Col, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useEffect, useMemo, useState } from "react"
import { BillingDataDatatable } from "../../Data/WebsiteData/moby"
import { selectDatatable } from "../../redux/templateData/selectors"
import { useDispatch, useSelector } from "react-redux"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import Modal from "../../components/Modal"
import { initialState } from "../../redux/templateData/init"
import { useModalCtx } from "../../providers/ModalProvider"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useFormik } from "formik"
import { FormHelperText, Grid, MenuItem, TextField } from "@mui/material"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { BillingFormValidations } from "../../validations"
import { addData, deleteData, editData } from "../../redux/actions"
import { selectWebsiteData } from "../../redux/general/selectors"

function BillingModel() {
    const dispatch = useDispatch()
    const website = useSelector(selectWebsiteData)
    const datatable = useSelector(selectDatatable)
    const module = "billing"

    const modalContext = useModalCtx()
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext

    const [isEditMode, setEditMode] = useState(false)
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                base_rate: "",
                locked_rate: "",
                unlocked_rate: "",
                is_holiday: false,
                rate_start_time: "",
                rate_end_time: "",
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (!isEditMode) {
                    if (obj?.rate_start_time) {
                        obj.rate_start_time = `${obj.rate_start_time}:00`
                    }

                    if (obj?.rate_end_time) {
                        obj.rate_end_time = `${obj.rate_end_time}:00`
                    }
                }

                console.log("type of boolean", typeof obj.is_holiday)

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: BillingFormValidations,
        })

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const columns = useMemo(
        () =>
            computeColumns({
                data: datatable.data,
                meta: BillingDataDatatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable.data],
    )

    const data = useMemo(() => datatable.data, [datatable.data])

    return (
        <div>
            <PageHeader titles="Subscriptions" active="Subscripitons" items={["Tables"]} />
            {/* <div className="row">
        <Col lg={12}>
          <div style={{ textAlign: 'right', marginBottom: '20px' }}>
            <Button
              onClick={() => {
                setEditMode(false);
                handleFormModalStatus(true);
                resetForm();
              }}
              variant="secondary"
            >
              Add Billing Details
            </Button>
          </div>
        </Col>
      </div> */}
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        {/* <Card.Header>
              <Card.Title as="h3">
                Billing Model (Currency: {website?.currency?.name || '$'})
              </Card.Title>
            </Card.Header> */}
                        <Card.Body>
                            <Datatable
                                addActionName="Add Billing Details"
                                addAction={() => {
                                    setEditMode(false)
                                    handleFormModalStatus(true)
                                    resetForm()
                                }}
                                refreshAction={() => {
                                    dispatch(fetchData({ module }))
                                }}
                                datatableTitle={`Billing Model (Currency: ${
                                    website?.currency?.name || "$"
                                })`}
                                sortable_key="commision"
                                isMultiSelect={false}
                                columns={columns}
                                data={data}
                                actions={[
                                    {
                                        name: "Edit",
                                        type: "edit",
                                        buttonType: "icon",
                                    },
                                    { name: "Delete", type: "delete", buttonType: "icon" },
                                ]}
                                handleModalStatus={setModalStatus}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                onActionClick={(event, { type, data }) => {
                                    setValues(data.original)
                                    if (type == "edit") {
                                        setEditMode(true)
                                        handleFormModalStatus(true)
                                    } else if (type == "delete") {
                                        setDialogConfirmationStatus(true)
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal
                modalHeading={isEditMode ? "Edit Billing Details" : "Add Billing Details"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                {console.log("doiaa", values)}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Base Rate*"
                            name="base_rate"
                            type="number"
                            error={!!(errors.base_rate && touched.base_rate)}
                            helperText={
                                errors.base_rate && touched.base_rate ? errors.base_rate : false
                            }
                            value={values.base_rate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Base Rate"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Vehicle Type"
                            name="vehicle_type"
                            select={true}
                            error={!!(errors.vehicle_type && touched.vehicle_type)}
                            helperText={
                                errors.vehicle_type && touched.vehicle_type
                                    ? errors.vehicle_type
                                    : false
                            }
                            value={values.vehicle_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Vehicle Type"
                        >
                            <MenuItem value={"Bike"}>Bike</MenuItem>
                            <MenuItem value={"Scooter"}>Scooter</MenuItem>
                            <MenuItem value={"Delivery - KG3"}>Delivery - KG3</MenuItem>
                            <MenuItem value={"Delivery - KG4"}>Delivery - KG4</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Locked Rate"
                            name="locked_rate"
                            type="number"
                            error={!!(errors.locked_rate && touched.locked_rate)}
                            helperText={
                                errors.locked_rate && touched.locked_rate
                                    ? errors.locked_rate
                                    : false
                            }
                            value={values.locked_rate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Locked Rate"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Unlocked Rate"
                            name="unlocked_rate"
                            type="number"
                            error={!!(errors.unlocked_rate && touched.unlocked_rate)}
                            helperText={
                                errors.unlocked_rate && touched.unlocked_rate
                                    ? errors.unlocked_rate
                                    : false
                            }
                            value={values.unlocked_rate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Unlocked Rate"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Is Holiday"
                            name="is_holiday"
                            select={true}
                            error={!!(errors.is_holiday && touched.is_holiday)}
                            helperText={
                                errors.is_holiday && touched.is_holiday ? errors.is_holiday : false
                            }
                            value={
                                typeof values.is_holiday === "boolean"
                                    ? values.is_holiday
                                    : values.is_holiday.toLowerCase() === "yes"
                                      ? true
                                      : values.is_holiday.toLowerCase() === "no"
                                        ? false
                                        : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Is Holiday"
                        >
                            <MenuItem value={false}>No</MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="rate_start_time"
                            label="Rate Start Time"
                            name="rate_start_time"
                            value={values.rate_start_time}
                            onChange={handleChange}
                            type="time"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            placeholder="Rate End Time"
                            InputProps={{
                                step: 1,
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
            <TextField
              id="rate_end_time"
              label="Rate End Time"
              name="rate_end_time"
              value={values.rate_end_time}
              onChange={handleChange}
              type="time"
              InputLabelProps={{shrink: true}}
              fullWidth
              placeholder="Rate End Time"
            />
          </Grid> */}
                </Grid>
            </Modal>
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        Are you sure want to delete this record?
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}

export default BillingModel
