import React, { useMemo } from "react"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import { polygonData } from "../../Data/DummyData"
import { useNavigate } from "react-router-dom"
import { GeofencingDatatable } from "../../Data/WebsiteData/moby"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"

function GeneralManageGeofencing() {
    const history = useNavigate()

    const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: GeofencingDatatable.data,
            meta: GeofencingDatatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = useMemo(() => GeofencingDatatable.data, [GeofencingDatatable.data])

    return (
        <div>
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">My Geofencing</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {/* <div className="table-responsive">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>No of vehicle</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {polygonData.map((d,i) => {
                        return(
                            <tr>
                                <td>{i+1}</td>
                                <td>{d.title}</td>
                                <td>{d.vehicle_list.length}</td>
                                <td>                    
                                    <Button size={"sm"} variant="primary" onClick={() => {history(`/geofencing/create-geofencing?id=${i}`)}}>View</Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                </Table>
            </div> */}
                            <Datatable columns={columns} data={data} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default GeneralManageGeofencing
